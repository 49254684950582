document.addEventListener("turbo:load", (e) => {
  const navbarButtons = document.querySelectorAll(".euro-navbar-active-container");
  const euroRegisters = document.querySelectorAll(".euro-register");
  const euroMyTeams = document.querySelectorAll(".euro-my-team");
  const euroMyEntriesSliders = document.querySelectorAll(".euro-my-entries-slider")
  const loadingModal = document.querySelector(".euro-loading-modal");
  const euroMyMiniLeagues = document.querySelectorAll(".euro-mini-league-container");
  const euroUserMenuButton = document.querySelectorAll(".euro-top-part-user-menu-icon-container");
  const euroUserMenuBuyCoinsButton = document.querySelectorAll(".euro-user-menu-buy-coins-button");

  loadingModal.style.display = "none";

  if (navbarButtons.length > 0) {
    navigationLoading(navbarButtons, loadingModal)
  }
  if (euroRegisters.length > 0) {
    navigationLoading(euroRegisters, loadingModal)
  }

  if (euroMyTeams.length > 0) {
    navigationLoading(euroMyTeams, loadingModal)
  }

  if (euroMyEntriesSliders.length > 0) {
    navigationLoading(euroMyEntriesSliders, loadingModal)
  }

  if (euroMyMiniLeagues.length > 0) {
    navigationLoading(euroMyMiniLeagues, loadingModal)
  }

  if (euroUserMenuButton) {
    navigationLoading(euroUserMenuButton, loadingModal)
  }

  if (euroUserMenuBuyCoinsButton) {
    navigationLoading(euroUserMenuBuyCoinsButton, loadingModal)
  }

  function navigationLoading(allButtons, loadingModal) {
    allButtons.forEach(allButton => {
      allButton.addEventListener("click", (e) => {
        loadingModal.style.display = "flex";
      })
    });
  }
})
